import 'react-app-polyfill/ie11'
import ReactDOM from 'react-dom'
import React from 'react'
import dotenv from 'dotenv'

import rollbar from '~/services/rollbar'
import ResultsMessage from '~/components/core/ResultsMessage'
import Hoome from './App'

dotenv.config({ path: `../.env.${process.env.NODE_ENV}`, debug: false })

rollbar.initialize()

const SvgComponent = () => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      width={200}
      height={200}
      viewBox='0 0 256 241'
    >
      <path fill='#4BB5AF' d='M254 188V2H2v186h111v29H75v22h106v-22h-38v-29h111zM19 19h217v151H19V19zm150.385 113.8c.131 1.179 0 2.575-.306 3.885l-11.131-10.258-10.04 10.957 11.262 10.214c-1.31.437-2.575.742-3.885.742-3.885.131-7.203-1.048-10.04-3.623-2.837-2.706-4.365-5.849-4.496-9.734 0-1.353.131-2.881.437-4.191l-2.706-2.575-16.195-14.798-27.413 30.207c-1.659 2.226-4.234 3.754-7.203 3.754a8.681 8.681 0 0 1-8.687-8.687c0-2.575 1.004-4.933 2.968-6.591l28.461-29.509-17.198-15.758c-1.353.611-2.881.917-4.191.917-3.885.131-7.203-1.048-10.04-3.623s-4.365-5.718-4.496-9.603c-.131-1.179 0-2.575.306-3.885l11.262 10.214 9.953-10.913-11.262-10.214c1.179-.437 2.575-.742 3.885-.742 3.885-.131 7.203 1.048 10.04 3.623 2.837 2.532 4.365 5.849 4.496 9.734.131 1.31 0 2.575-.306 3.885l17.723 16.238 12.965-14.012-19.381-17.068 17.592-20.036 49.807 43.826-17.592 20.036-19.905-17.548-12.528 14.798 19.469 17.81c1.31-.437 2.575-.742 3.885-.742 3.885-.131 7.203 1.048 10.04 3.623 2.835 2.532 4.363 5.675 4.45 9.647z' />
    </svg>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TemporaryDown: React.FC = () => (
  <div style={{
    marginTop: '120px'
  }}>
    <ResultsMessage
      title='האתר נמצא תחת עבודות תחזוקה'
      subTitle='מתנצלים על חוסר הנוחות, נחזור בקרוב.'
      icon={<SvgComponent />}
    />
  </div>
)

const App: React.FC = () => {
    return (
        <Hoome />
    )
}

export default App

ReactDOM.render(<App />, document.getElementById('root'))
